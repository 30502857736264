import { Injectable } from '@angular/core';
import { PageService } from './page.service';

@Injectable({
   providedIn: 'root',
})
export class UrlService {

   constructor(
      private page: PageService) {}

   public makeUrl(name: string, params: {[key: string]: string}): string {
      // Must correspond to routing.py
      if (name === 'edge-server') {
         return '/users/' + params.userId + '/edge#/' + params.edgeServerId;
      }
      if (name === 'edge-server-services') {
         return '/edge/' + params.edgeServerId + '/services?context=' + this.page.page.context;
      }
      if (name === 'edge-server-endpoints') {
         return '/edge/' + params.edgeServerId + '/services/' + params.serviceId + '/endpoints?context=' + this.page.page.context;
      }
      if (name === 'edge-server-endpoint') {
         return '/edge/' + params.edgeServerId + '/services/' + params.serviceId + '/endpoints?context=' + this.page.page.context + '#/' + params.endpointId;
      }
      if (name === 'arrivalsFolders') {
         return '/accounts/' + params.accountId + '/arrivals';
      }
      if (name === 'arrivalsFolder') {
         return '/accounts/' + params.accountId + '/arrivals#' + params.arrivalsFolderId;
      }
      if (name === 'edge-server-ingest-queue') {
         return '/edge/' + params.edgeServerId + '/ingestQueueNG?thumbsize=1.0';
      }
      if (name === 'edge-server-ingest-queue-files') {
         return '/edge/' + params.edgeServerId + '/ingestQueueNG?mode=files';
      }
      if (name === 'edge-server-ingest-queue-streams') {
         return '/edge/' + params.edgeServerId + '/ingestQueueNG?mode=streams&thumbsize=1.0';
      }
      if (name === 'edge-server-unprocessed-files') {
         return '/edge/' + params.edgeServerId + '/ingestQueueNG?mode=unprocessed';
      }
      if (name === 'arrivals-folder-ingest-queue') {
         return '/accounts/' + params.accountId + '/arrivals/' + params.arrivalsFolderId + '/ingestQueueNG?thumbsize=1.0';
      }
      if (name === 'arrivals-folder-ingest-queue-streams') {
         return '/accounts/' + params.accountId + '/arrivals/' + params.arrivalsFolderId + '/ingestQueueNG?mode=streams?thumbsize=1.0';
      }
      if (name === 'arrivals-folder-ingest-queue-files') {
         return '/accounts/' + params.accountId + '/arrivals/' + params.arrivalsFolderId + '/ingestQueueNG?mode=files';
      }
      if (name === 'arrivals-folder-unprocessed-files') {
         return '/accounts/' + params.accountId + '/arrivals/' + params.arrivalsFolderId + '/ingestQueueNG?mode=unprocessed';
      }
      if (name === 'user') {
         return '/users/user_settings/' + params.userId + '/';
      }
      if (name === 'account-settings') {
         return '/accounts/' + params.accountName + '/';
      }
      throw new Error('makeUrl unknown url name ' + name);
   }
}
