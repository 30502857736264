<div class="btn-group fbdn-button-justified">
   <a
      class="fbdn-button fbdn-button-horizontal fbdn-button-body btn btn-default btn-drop-main"
      href="/accounts/{{accountId}}/ingestQueueNG?thumbsize=1.0"
      title="Ingest by account"
   >
      <span class="material-icon action-icon"> dvr </span>
      Ingest
   </a>
   <div class="fbdn-dropdown dropdown" bbMenuDirection="mouseenter">
      <button type="button" class="fbdn-dropdown-toggle dropdown-toggle btn btn-default btn-drop-main" dropdown-toggle></button>
      <ul class="dropdown-menu" role="menu">
         <li>
            <a href="/accounts/{{accountId}}/ingestQueueNG?thumbsize=1.0">
               Ingest
            </a>
         </li>
         <li>
            <a href="/accounts/{{accountId}}/ingestQueueNG?mode=files">
               Files Ingest
            </a>
         </li>
         <li>
            <a href="/accounts/{{accountId}}/ingestQueueNG?mode=streams&thumbsize=1.0">
               Streams Ingest
            </a>
         </li>
         <li>
            <a href="/accounts/{{accountId}}/ingestQueueNG?mode=unprocessed">
               Watch Items
            </a>
         </li>
      </ul>
   </div>
</div>
