import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Subject, forkJoin, Observable, of , timer} from 'rxjs';
import { takeUntil, map, switchMap } from 'rxjs/operators';
import { MatTable } from '@angular/material/table';

import { AutocompleteOptionGroup } from '../../components/autocomplete-item/autocomplete-option-group.interface';
import { UserSummary } from '../../types/user-summary.interface';
import { SelectableOption } from '../../dynamic-form/form.interface';
import { SimpleErrorStateMatcher } from '../../utils/forms/parent-child-invalid-matcher';
import { ManagerType, RoleName } from '../../types';
import { ManagerUserAccessApiService } from '../../services/manager-useraccess.api.service';
import { SitesApiService } from '../../services/sites.api.service';
import { UrlService } from '../../services/url.service';
import { UsersApiService } from '../../api/users.api.service';
import { Me } from '../../../store/users';
import { Pagination } from '../api-paginator/pagination.interface';
import { AlertService } from '../../services/alert.service';
import { UsersSearchParams } from '../../../store/users';

interface UserSuggestion {
   groupName: string;
   children: UserSummary[];
}

@Component({
   selector: 'bb-add-remove-managers',
   templateUrl: './add-remove-managers.component.html',
   styleUrls: ['./add-remove-managers.component.scss'],
})
export class AddRemoveManagersComponent implements OnInit {
   @Input() managerType: ManagerType;
   @Input() roleName: RoleName;
   @Input() roleId: string;
   @Input() sitegroupId: string | undefined;
   @Input() siteId: string | undefined;
   @Input() accountId: string | undefined;
   @Input() canadd: string;
   @Input() candelete: string;
   @Input() canremoveself: string;

   @ViewChild(MatTable) managersTable: MatTable<UserSummary[]>;

   public displayedColumns: string[] = ['username', 'fullname', 'email', 'emailverified'];
   public deletedManagerIdx: number[] = [];
   public newManagerIdx: number[] = [];
   public userIdxEmailUpdate: number[] = [];
   public ready = false;
   public myManagerType: string;
   public filteredOptions$: Observable<AutocompleteOptionGroup[]>;
   public dataSource: UserSummary[] = [];
   public addManagerFormControl = new FormControl('',
      {
         validators: [Validators.required],
         asyncValidators: [this.userExistsValidator()],
      }
   );
   public matcher = new SimpleErrorStateMatcher();
   public pagination = {
      admin: {offset: 0, limit: 100},
      account: {offset: 0, limit: 100},
      site: {offset: 0, limit: 100},
      sitegroup: {offset: 0, limit: 100},
   };

   private destroy$ = new Subject<void>();
   private userSuggestions: UserSuggestion[] = [];
   private me: Me;
   private managerPageId = {};

   constructor(
      private alertService: AlertService,
      public urlService: UrlService,
      private managerUserAccessApiService: ManagerUserAccessApiService,
      private sitesApiService: SitesApiService,
      private usersApiService: UsersApiService,
   ) {}

   ngOnInit() {
      this.managerPageId = { admin: '', site: this.siteId, sitegroup: this.sitegroupId, account: this.accountId};
      this.reset();
      if( this.candelete === 'True' ) {
         this.displayedColumns.push('delete');
      }
   }

   public disabledRow(index: number): boolean | undefined {
      if (this.dataSource.length > index) {
         return this.dataSource[index].disabled || (this.dataSource[index].username === this.me.username && this.canremoveself ===  'False');
      }
   }

   public newRow(index: number): boolean | undefined {
      if (this.dataSource.length > index) {
         return this.dataSource[index].new;
      }
   }

   public getErrorMessage(): string {
      if ( this.addManagerFormControl.hasError('required') ) {
         return 'User name required';
      }
      if ( this.addManagerFormControl.hasError('userUndefined') ) {
         return 'User name invalid';
      }
      if ( this.addManagerFormControl.hasError('userPresent') ) {
         return 'User already manager';
      }
   }

   public markAsDeleted(atIndex: number): void {
      if ( this.dataSource.length > atIndex ) {
         this.dataSource[atIndex].disabled = true;
         if ( this.deletedManagerIdx.indexOf(atIndex) === -1 && this.newManagerIdx.indexOf(atIndex) === -1 ) {
            this.deletedManagerIdx.push(atIndex);
         }
         if ( this.newManagerIdx.indexOf(atIndex) >= 0) {
            this.newManagerIdx.splice(this.newManagerIdx.indexOf(atIndex), 1);
            this.dataSource[atIndex].new = false;
         }
      }
   }

   public updateManagers(): void {
      const requestList = [];
      if (this.deletedManagerIdx.length) {
         requestList.push(
            ...this.deletedManagerIdx.map(idx => { return this.managerUserAccessApiService.deleteManagerAccess(this.managerType, this.dataSource[idx].id, this.managerPageId[this.managerType]);
            })
         );
      };
      if (this.newManagerIdx.length) {
         requestList.push(
            ...this.newManagerIdx.map(idx => {
               this.dataSource[idx].new = false;
               return this.managerUserAccessApiService.addManagerAccess(this.managerType, this.dataSource[idx].id, this.roleId, this.managerPageId[this.managerType]);
            })
         );
      }
      if (this.userIdxEmailUpdate.length) {
         requestList.push(
            ...this.userIdxEmailUpdate.map(idx => {
               return this.usersApiService.updateUserFields(this.dataSource[idx].id, { emailverified: this.dataSource[idx].emailverified });
            })
         );
      }

      forkJoin(...requestList)
         .pipe(
            this.alertService.notifyOnError(this.managerType),
            takeUntil(this.destroy$),
         )
         .subscribe(() => {
            this.alertService.show({
               text: 'Updated ' + this.managerType + ' manager' + (((this.deletedManagerIdx.length + this.newManagerIdx.length) > 1) ? 's' : ''),
               type: 'success',
            });
            this.deletedManagerIdx = [];
            this.newManagerIdx = [];
            this.userIdxEmailUpdate = [];
            this.reset();
         });
   }


   public discardChanges(): void {
      this.userIdxEmailUpdate.forEach(index => this.dataSource[index].emailverified = '0');
      this.userIdxEmailUpdate = [];
      this.deletedManagerIdx.forEach(index => this.dataSource[index].disabled = false);
      this.deletedManagerIdx = [];
      this.newManagerIdx.forEach(idx => this.dataSource.splice(idx, 1));
      this.newManagerIdx = [];
      this.reset();
   }

   public autocompleteFormChange(value: string): void {
      if (!value || value.length < 2) {
         this.userSuggestions.splice(0);
         this.filteredOptions$ = this.getFilteredOptions(value);
      } else {
         const userObs$: Observable<UserSummary[]>[]=[];
         if (Object.entries(this.me.admin).length) {
            const searchParams: UsersSearchParams = { searchText: value, limit: 20};
            userObs$.push(this.usersApiService.findUsers(searchParams, false)
                              .pipe(
                                 map(usersArr => usersArr.map(user => {return {username: user.username, fullname: user.fullname, id: user.id, email: user.email} as UserSummary;}))
                              )
            );
         }
         else {
            this.me.sites.forEach(site => userObs$.push(this.sitesApiService.getSiteUsers(site.id, value)));
         }

         forkJoin(userObs$)
            .pipe(
               this.alertService.notifyOnError('loading site users'),
               takeUntil(this.destroy$),
            )
            .subscribe(resultingUsers => {
               // Fill in the previous role for users already on the account (accountUsers includes managers too)
               // and disable if user already has an assigned role
               const mergedUsers = [].concat(...resultingUsers);
               const notYetManagers: UserSummary[] = [];
               if (!mergedUsers.find(user => (user.id === this.me.id))) {
                  mergedUsers.push({ username: this.me.username, fullname: this.me.fullname, email: this.me.email, id: this.me.id, emailverified: (this.me.emailVerified)? '1': '0'});
               }
               mergedUsers.forEach(user => {
                  if ( this.dataSource.filter( dataSourceUser => dataSourceUser.username === user.username ).length === 0 ) {
                     notYetManagers.push({ username: user.username, fullname: user.fullname, email: user.email, id: user.id, emailverified: user.emailverified });
                  }
               });
               this.userSuggestions.splice(0);
               this.userSuggestions.push({ groupName: '', children: notYetManagers});
               this.filteredOptions$ = this.getFilteredOptions(value);
            });
      }
   }

   public onAutocompleteSelectionChanged(value: string) {
      this.userSuggestions.forEach(userSuggestion => {
         userSuggestion.children.forEach(child => {
            if (child.username === value || child.fullname === value) {
               this.dataSource.push({id: child.id, username: child.username, fullname: child.fullname, roleId: this.roleId, rolename: this.roleName, email: child.email, emailverified: child.emailverified, new: true});
               this.newManagerIdx.push(this.dataSource.length-1);
               this.refresh();
            }
         });
      });
   }

   public addManager(): void {
         this.managerUserAccessApiService.getUserInfo(this.addManagerFormControl.value)
            .pipe(
               this.alertService.notifyOnError('updating users'),
               takeUntil(this.destroy$),
            )
            .subscribe( userInfo => {
               userInfo.forEach(user => {
                  this.dataSource.push({id: user.id, username: user.username, fullname: user.fullname, roleId: this.roleId, rolename: this.roleName, email: user.email, new: true});
                  this.newManagerIdx.push(this.dataSource.length-1);
               });
               this.refresh();
            });
   }

   public canSearchSiteUsers(): boolean {
      const roleNameAllowed: string[] = ['Account Manager', 'Site Manager', 'SiteManager SSO'];
      return roleNameAllowed.indexOf(this.roleName) > -1;
   }

   public userEditUrl(userId: string): string {
      return this.urlService.makeUrl('user', { userId: userId });
   }

   public emailString(emailAddress: string): string {
      const emailString="mailto:" + emailAddress;
      return emailString;
   }

   public onPaginationChange(event: Pagination){
      this.pagination[this.managerType].offset = Number.isNaN(event.startIndex) ? this.pagination[this.managerType].offset: event.startIndex;
      this.pagination[this.managerType].limit = Number.isNaN(event.pageSize) ? this.pagination[this.managerType].limit: event.pageSize;
      this.reset();
   }

   public userVerifiedChanged(check: boolean, index: number): void {
      if (this.dataSource.length > index && check) {
         this.dataSource[index].emailverified = '2'; //emailverified to be set as forced, allowing to login without a verification
         if (!this.userIdxEmailUpdate.includes(index)) {
            this.userIdxEmailUpdate.push(index);
         }
      }
   }

   public verifiedStatus(index: number): boolean {
      if ( this.dataSource.length > index) {
         return this.dataSource[index].emailverified > '0';
      }
   }
   public saveEnabled(): boolean {
      return this.userIdxEmailUpdate.length > 0 || this.newManagerIdx.length > 0 || this.deletedManagerIdx.length > 0;
   }

   private reset(): void {
      const offset = (this.pagination[this.managerType] === undefined) ? this.pagination.account.offset: this.pagination[this.managerType]?.offset;
      const limit = (this.pagination[this.managerType] === undefined) ? this.pagination.account.limit: this.pagination[this.managerType]?.limit;
      forkJoin({
         managerRoles: this.managerUserAccessApiService.getRoles(),
         managers : this.managerUserAccessApiService.getManagers(this.managerType, this.managerPageId[this.managerType], offset, limit),
         me: this.usersApiService.fetchMe(),
      })
         .pipe(
            this.alertService.notifyOnError('loading managers'),
            takeUntil(this.destroy$),
         )
         .subscribe(({managerRoles, managers, me}) =>  {
            this.dataSource = managers.filter(manager => manager.roleId === this.roleId);
            this.me = me;
            this.addManagerFormControl.reset('');
            this.ready = true;
         });
   }

   private refresh(): void {
      if ( this.managersTable ) {
         this.managersTable.renderRows();
      }
   }

   private getFilteredOptions(value: string): Observable<AutocompleteOptionGroup[]> {
      return of(this.userSuggestions)
         .pipe(
            map(suggestions => suggestions.map(suggestion => this.filterGroup(suggestion, value)))
         );
   }

   private filterGroup(suggestion: UserSuggestion, value: string): AutocompleteOptionGroup {
      value = value.toLowerCase();
      const filteredChildren = suggestion.children
         .map(child => this.filterChildren(child, value))
         .filter(vl => !!vl);
      return {
         groupName: suggestion.groupName,
         children: filteredChildren,
      };
   }

   private filterChildren(child: UserSummary, value: string): SelectableOption {
      if (child.username?.toLowerCase().includes(value) || child.fullname?.toLowerCase().includes(value)) {
         return {
            value: child.username,
            label: child.fullname,
            disabled: child.disabled,
            additionalInfo: child.additionalInfo,
         };
      }
   }

   private userExistsValidator(): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors | null> => {
         return timer(200).pipe(
            switchMap(() => this.managerUserAccessApiService.getUserInfo(control.value).pipe(
               map(users => this.inputIsValidUser(control.value, users)),
            )),
         );
      };
   }

   private inputIsValidUser(input: string, users: UserSummary[]): ValidationErrors {
      const user = users.find(u => (u.username === input || (u?.fullname && u.fullname.trim().toLowerCase() === input.trim().toLowerCase())));
      if ( user ) {
         return this.dataSource.find(u => u.username === input || (u?.fullname && u.fullname.trim().toLowerCase() === input.trim().toLowerCase())) ? { userPresent: true } : null;
      }
      else {
         return { userUndefined: true };
      }
   }

}
