import { InjectionToken } from '@angular/core';

export const GRID_ONLY = new InjectionToken<string>('');
export const EDGE_ID = new InjectionToken<string>('edgeId');
export const ARRIVALS_ID = new InjectionToken<string>('arrivalsId');
export const ENDPOINT_ID = new InjectionToken<string>('endpointId');
export const ACCOUNT_ID = new InjectionToken<string>('accountId');
export const PAGE_MODE = new InjectionToken<string>('pageMode');
export const THUMB_SIZE = new InjectionToken<string>('thumbSize');

