import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Service } from '../../edge-services/service.interface';

import { CreateServiceDto, EdgeService, SaveServiceDto, ServiceTemplateDto, ServiceType } from '../../store/edge-server-services/types';
import { AsyncCacheService } from '../utils/async-cache.service';
import { Assignment } from '../types/assignment.interface';
import { Skeleton } from '../types/skeleton.interface';


@Injectable({
   providedIn: 'root',
})
export class EdgeServicesApiService {
   private asyncCache: any;

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<Assignment>,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache('EdgeServicesApiService');
   }

   public fetchServices(edgeId: string, forceFetch=false): Observable<EdgeService[]> {
      const path = `/api/edgeServers/${edgeId}/services`;
      const api =  this.http.get<EdgeService[]>(path);
      return this.asyncCache.fetch(path, api, forceFetch);
   }

   public fetchService(serviceId: string, forceFetch=false): Observable<EdgeService> {
      const path = `/api/services/${serviceId}`;
      const api =  this.http.get<EdgeService>(path);
      return this.asyncCache.fetch(path, api, forceFetch);
   }

   public fetchServiceTypes(edgeId: string, forceFetch=false): Observable<ServiceType[]> {
      const path = `/api/edgeServers/${edgeId}/serviceTypes`;
      const api =  this.http.get<ServiceType[]>(path);
      return this.asyncCache.fetch(path, api, forceFetch);
   }

   public fetchServiceType(serviceTypeId: string, forceFetch=false) {
      const path = `/api/serviceTypes/${serviceTypeId}`;
      const api = this.http.get<ServiceType>(path);
      return this.asyncCache.fetch('service-type-' + serviceTypeId, api, forceFetch);
   }

   public fetchServiceTemplates(edgeId: string, forceFetch=false): Observable<ServiceTemplateDto[]> {
      const path = `/api/edgeServers/${edgeId}/serviceTemplates`;
      const api =  this.http.get<ServiceTemplateDto[]>(path);
      return this.asyncCache.fetch(path, api, forceFetch);
   }

   public saveService(saveServiceDto: SaveServiceDto): Observable<EdgeService> {
      return this.http.post<EdgeService>(`/api/services/${saveServiceDto.id}`, saveServiceDto);
   }

   public saveServiceName(id: string, name: string): Observable<EdgeService> {
      return this.http.post<EdgeService>(`/api/services/${id}`, {name});
   }

   public createService(createServiceDto: CreateServiceDto): Observable<EdgeService> {
      return this.http.post<EdgeService>(`/api/edgeServers/${createServiceDto.edgeServerId}/services`, createServiceDto);
   }

   public deleteService(serviceId: string): Observable<void> {
      return this.http.post<void>(`/api/services/${serviceId}/trash`, {});
   }

   public performAction(serviceId: string, action: string, data: any = {}): Observable<void> {
      return this.http.post<void>(`/api/services/${serviceId}/actions/${action}`, data);
   }

   public fetchServiceQueryResults(service: Service, queryName: string, data: any = {}): Observable<any> {
      const stringifiedJsonData = JSON.stringify(data || {});
      const queryParams = new HttpParams()
         .set('data', stringifiedJsonData);
      return  this.http.get<any>('/api/services/' + service.id + '/queries/' + queryName, { params:  queryParams});
   }
}
