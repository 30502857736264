import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef, Renderer2, HostListener, AfterViewInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ObservableState } from '../../types';
import { Pagination } from '../api-paginator/pagination.interface';
import { ColumnType, CellRenderInfo } from './column-type.interface';
import { ActiveStateTableCell, DateTableCell, HandlerTableCell, LinkTableCell, SelectableTableCell, TableCell, TableCellsSpec, TextTableCell } from './table-cell.interface';
import { ErrorMessageService } from '../../services/error-message.service';
import { EN_GB_LOCALE, LONG_DATE_FORMAT } from '../../utils';
import { NaturalDatePipe } from '../../filters/natural-date.pipe';
import { formatDate } from "@angular/common";

interface TableRenderInfo {
   [key: string]: CellRenderInfo;
}

type Row<T> = { [key in keyof T]: TableCell };

/**
 * Generic Table component for potentially large sets of data. Contains ApiPaginatorComponent and expects a total
 * count of all data for the pagination.
 *
 * @param {Input} tableData$ stream of data to display in the table
 * @param {Input} fetchingData set externally before and after data is re-fetched
 * @param {Input} columnSchema schema for each column. Optionally mark *ONE* column as `selectable: true`. When rows in
 *                             this column are selected, `selectionChanged` emits `uniqueIdentifier`[] for provided entity
 * @param {Input} pageSize the desired page size to initialise the table with (passed to ApiPaginatorComponent)
 * @param {Input} totalRowCount total count of all rows including rows we have not received from the server
 * @param {Input} [uniqueIdentifier='id'] property of each tableData$ row to be emitted when selected (see columnSchema `selectable: true`)
 * @param {Input} [entityPluralName='data'] pluralised version of T to display in message when no results are found or table is an error state
 * @param {Input} clearSelection optional flag to provide to trigger a clearing of all the selected rows
 * @param {Input} initialSort optional initial Sort to set for the table. Data should be passed pre-sorted from API, this just sets UI arrow on header
 * @param {Input} resizeEnabled optional, support for resizable columns in schema, table row is now controlled by sum of columns width
 * @param {Input} headerHeight optional header height to be taken into account to allocate table height as part of the browser
 * @param {Input} sidebarWidth option sidebar width to be taken into account for table width
 * @param {Input} resetOnTotalRowChange default true, when set page count reset to 1 when page size change
 * @param {Input} hideAllItems default false, when set, no all Items option avaliable in the paginator
 * @param {Input} hideFirstLast default false, when set, disable goto first/last page in paginator
 * @param {Input} resetStart default false, when set, reset paginator to first page  with current page size
 * @param {Input} hideTotalItems string default undefined. when set overrides the pagination totalcount with the string passed
 * @param {Input} nextPageDisabled disables the page next of the paginator when set to true
 *
 * @param {Output} sortChanged emits when sort header of a column with `sortable: true` is clicked
 * @param {Output} paginationChanged emits when either page size or page number changes
 * @param {Output} selectionChanged emits when any or all rows are selected or deselected
 */
@Component({
   selector: 'bb-table',
   templateUrl: './table.component.html',
   styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends Row<T>> implements OnChanges, OnInit, AfterViewInit {
   @Input() tableData$: Observable<T[]>;
   @Input() fetchingData: boolean;
   @Input('columnSchema') schema: Map<string, ColumnType<TableCellsSpec>>;
   @Input() clearSelection: boolean;
   @Input() totalRowCount: number;
   @Input() uniqueIdentifier = 'id';
   @Input() entityPluralName = 'data';
   @Input() pageSize: number;
   @Input() initialSort: Sort;
   @Input() resizeEnabled = false;
   @Input() headerHeight = 200;
   @Input() sidebarWidth = 50;
   @Input() resetOnTotalRowChange = true;
   @Input() hideAllItems = false;
   @Input() hideFirstLast = false;
   @Input() resetStart = false;
   @Input() hideTotalItems: string;
   @Input() nextPageDisabled: boolean;

   @Output() sortChanged = new EventEmitter<Sort>();
   @Output() paginationChanged = new EventEmitter<Pagination>();
   @Output() selectionChanged = new EventEmitter<string[]>();

   public state$: Observable<ObservableState<MatTableDataSource<T>>>;
   public columnNames: string[] = [];
   public selectedData: { [key: string]: boolean } = {};
   public allRowsSelected = false;
   public optionalColumnNames: string[] = [];
   public selectableColumnKey: string = null;
   public selectableColumnName: string = this.uniqueIdentifier;
   public fetching: boolean;
   public sortSelected: Sort;
   public dropdownMenuIsOpen = false;

   private placeholderRows: T[];
   private tableRenderInfo: Map<string, CellRenderInfo>;
   private marshalledTable: TableCellsSpec[] = [];
   private dropdownTimeoutHandle: NodeJS.Timeout | null;
   private dropdownMenuTimeout = 4000; // ms

   constructor(
      private errorMessageService: ErrorMessageService,
      private elementRef: ElementRef,
      private renderer: Renderer2,
      private naturalDatePipe: NaturalDatePipe,
   ) {}

   @HostListener('window:resize', ['$event'])
      onResize(event: Event): void {
         this.updateTableContainerSize();
      }

   ngOnInit() {
      this.tableRenderInfo = new Map<string, CellRenderInfo>();
      this.schema.forEach((value: ColumnType<T>, key: string) => this.tableRenderInfo.set(value.key as string, { cellType: value.cellType, dynamicComponent: value.dynamicComponent, handlerFunct: value.handlerFunct, dynamicComponentExtraInputsObj: value.dynamicComponentExtraInputsObj, dynamicOutputs: value.dynamicOutputs }));
      this.selectableColumnKey = this.getSelectableColumn();
      this.state$ = this.tableData$.pipe(
         map(data => {
            if (data) {
                  this.marshalledTable = this.marshallTable(data, this.tableRenderInfo);
                  this.updateAllSelected(this.marshalledTable);
               };
            return { loading: !data, value: new MatTableDataSource(this.marshalledTable as T[] || this.placeholderRows) };
         }),
         catchError(error => of({ loading: false, error: this.errorMessageService.errorMessage(error) })),
      );
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes.schema) {
         this.fetching = true;
         this.tableRenderInfo = new Map<string, CellRenderInfo>();
         this.schema.forEach((value: ColumnType<T>, key: string) => this.tableRenderInfo.set(value.key as string, { cellType: value.cellType, dynamicComponent: value.dynamicComponent, handlerFunct: value.handlerFunct, dynamicComponentExtraInputsObj: value.dynamicComponentExtraInputsObj, dynamicOutputs: value.dynamicOutputs }));
         this.selectableColumnKey = this.getSelectableColumn();
         this.selectableColumnKey = this.getSelectableColumn();
         this.optionalColumnNames = this.getOptionalColumnNames();
         this.columnNames = this.getActiveColumnNames();
         this.placeholderRows = this.generatePlaceholderRows();
         this.fetching = false;
         }
      if(changes.uniqueIdentifier) {
         this.selectableColumnName = this.uniqueIdentifier;
      }
      if(changes.fetchingData) {
         this.fetching = this.fetchingData;
      }
      if (this.clearSelection) {
         this.clearAllSelectedRows();
      }
      if(changes.initialSort) {
         this.sortSelected = this.initialSort;
      }
   }

   ngAfterViewInit() {
      this.updateTableContainerSize();
   }

   public updateAllSelected(data: any[]): void {
      const selectedRows = Object.entries(this.selectedData).filter(([key, selected]) => {
         // Filter by is selected + is in the currently visible (paged) data
         return selected && data.some(el => {
            if (typeof el[this.selectableColumnName].selectValue === "string") {
               return key === el[this.selectableColumnName].selectValue;
            }
            else {
               return key === el[this.selectableColumnName].selectValue.toString();
            }
         });
      });
      this.allRowsSelected = selectedRows.length && selectedRows.length === data.length;
      const selectedIds = selectedRows.map(([row, selected]) => row);
      this.selectionChanged.emit(selectedIds);
   }

   public selectAll(data: T[], selected: boolean): void {
      data.forEach((column) => {
         const id = column[this.selectableColumnName].selectValue;
         this.selectedData[id] = selected;
      });
      this.updateAllSelected(data);
   }

   public isTableColumn(colName: string): boolean {
      return colName !== 'Select' && colName !== 'dropDownColsMenu';
   }

   public updateVisibleColumns(key: string, status: boolean): void {
      this.schema.get(key).optionalEnabled = status;
      this.columnNames = this.getActiveColumnNames();
      clearTimeout(this.dropdownTimeoutHandle);
      this.dropdownTimeoutHandle = setTimeout(() => {
         this.dropdownMenuIsOpen = false;
      }, this.dropdownMenuTimeout);
   }

   public openCloseOptMenu(): void {
      if (!this.dropdownMenuIsOpen) {
         this.dropdownMenuIsOpen = true;
      }
      else {
         this.dropdownMenuIsOpen = false;
      }
   }

   public onMenuClosed(): void {
      clearTimeout(this.dropdownTimeoutHandle);
   }

   public getColumnPosition(colName: string): number {
      return this.columnNames.indexOf(colName);
   }

   public onSortChange(sort: Sort): void {
      this.sortChanged.emit(sort);
   }

   public onPaginationChange(pagination: Pagination): void {
      this.paginationChanged.emit(pagination);
   }

   private updateTableContainerSize(): void {
      const container = this.elementRef.nativeElement.querySelector('.table-container');
      if( container ) {
         const windowHeight = window.innerHeight;
         const windowWidth = window.innerWidth;
         const maxHeight = windowHeight - this.headerHeight;/* page header plus search bar and paginator */;
         const maxWidth = windowWidth - this.sidebarWidth;
         this.renderer.setStyle(container, 'max-height', `${maxHeight}px`);
         this.renderer.setStyle(container, 'max-width', `${maxWidth}px`);
      }
   }


   private getActiveColumnNames(): string[] {
      const columnNames: string[] = [];
      if(this.selectableColumnKey) { columnNames.push("Select"); };
      columnNames.push(...Array.from(this.schema.keys()).filter((key) => { return this.isVisibleColumn(key); }));
      if (this.getOptionalColumnNames().length) { columnNames.push("dropDownColsMenu"); };
      return columnNames;
   }

   private getOptionalColumnNames(): string[] {
      return Array.from(this.schema.entries())
         .filter(([key, value]) => typeof value === 'object' && value?.optionalCol === true )
         .map(([key, _]) => key);
   }

   private clearAllSelectedRows(): void {
      this.selectedData = {};
      this.updateAllSelected([]);
   }

   private isVisibleColumn(colName: string): boolean {
      const column = this.schema.get(colName);
      return column !== undefined && (!column?.hidden && (!column?.optionalCol || (column.optionalCol && column.optionalEnabled)));
   }

   private getSelectableColumn(): string {
      let foundKey: string = null;
      this.schema.forEach((value, key) => {
         if (value.hasOwnProperty('selectable')) {
           foundKey = key;
         }
       });
       return foundKey;
   }

   private generatePlaceholderRows(): T[] {
      const placeholderRow = this.columnNames.reduce((prev, curr) => ({
         ...prev,
         [((curr === 'Select') || (curr === 'dropDownColsMenu')) ? this.selectableColumnName: this.schema.get(curr).key]: {
            text: '...',
         },
      }), {} as T);
      return Array(this.pageSize).fill(placeholderRow);
   }

   private marshallTable(tableObj: any[], tableRendering: Map<string, CellRenderInfo>): TableCellsSpec[] {
      const resultTable: TableCellsSpec[] = [];
      tableObj.forEach(row => {
         const resultObj = {};
         tableRendering.forEach((value: CellRenderInfo, key: string) => {
            if( tableRendering.get(key).cellType === "SelectableTableCell" ) {
               resultObj[key] = { text: row[key], selectValue: row[key] } as SelectableTableCell;
            }
            else if ( tableRendering.get(key).cellType === "TextTableCell" ) {
               resultObj[key] = { text: row[key] } as TextTableCell;
            }
            else if ( tableRendering.get(key).cellType === "DateTableCell" ) {
               resultObj[key] = (row[key] !== null) ? { text: formatDate(row[key], "MMM dd YYYY HH:mm", EN_GB_LOCALE), tooltip: formatDate(row[key], LONG_DATE_FORMAT, EN_GB_LOCALE) } as DateTableCell : { text: "-"} as DateTableCell;
            }
            else if ( tableRendering.get(key).cellType === "DynamicComponentTableCell" ) {
               let inputObj = { data: row };
               if ( tableRendering.get(key).dynamicComponentExtraInputsObj) {
                  inputObj = { ...inputObj, ...tableRendering.get(key).dynamicComponentExtraInputsObj };
               }
               resultObj[key] = {
                  component: tableRendering.get(key).dynamicComponent,
                  inputs: inputObj,
               };
               if ( tableRendering.get(key).dynamicOutputs ) {
                  resultObj[key].outputs = tableRendering.get(key).dynamicOutputs;
               }
            }
            else if ( tableRendering.get(key).cellType === "LinkTableCell" ) {
               resultObj[key] = { text: row[key], href: `/test/${row[key]}/` } as LinkTableCell;
            }
            else if ( tableRendering.get(key).cellType === "ActiveStateTableCell" ) {
               resultObj[key] = { active : !row[key] } as ActiveStateTableCell;
            }
            else if ( tableRendering.get(key).cellType === "HandlerTableCell" ) {
               resultObj[key] = tableRendering.get(key).handlerFunct(row, key) as HandlerTableCell;
            }
         });
         resultTable.push(resultObj as TableCellsSpec);
      });
      return resultTable;
   }

}
