import { NavigationService } from "../navigation/navigation.service";
import { WatchedObject, NewStatefulObject, StatefulObject, StatefulWatchedObject } from "./transaction-objs";
import { cloneDeep, omitBy, defaults, isEmpty, pickBy, clone, lMap, assign, deepEqual, omit, filterKeysStartingWith } from "../funct-utils";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";

@Injectable({
   providedIn: 'root',
})
export class SaveTransactionService {
   constructor(
      @Inject('NavigationService') private navigationService: NavigationService,
   )
   {}

   public prepare<T>(object: T, deepKeys?: (keyof T)[]): T {
      object = defaults(object, {
         $$deepKeys: deepKeys,
         $$savingStates: {},
         $$saving: false,
         $$deleting: false,
         $$dirty: false,
         $$state: 'clean'
      });
      this.saveSaved(object);
      return object;
   }

   public save<T extends StatefulObject<T>>(object: T, key: keyof T, setter: (obj: T, key: keyof T) => Observable<any>): Observable<any>
   {
      if (object?.$$saved && object[key] === object.$$saved[key]) {
         return throwError(null);
      }
      object.$$saving = true;
      object.$$savingStates[key] = 'saving';
      return setter(object, key)
         .pipe(
            map((result) => {
               object.$$saved[key] = cloneDeep(object[key]);
               return result;
            }),
            catchError((error) => {
               this.rollbackProperty(object, key);
               return throwError(error);
            }),
            finalize(() => {
               delete object.$$savingStates[key];
               if (isEmpty(object.$$savingStates)) {
                  object.$$saving = false;
               }
            }),
         );
   }

   public rollbackProperty<T extends StatefulObject<T>>(object: T, key: keyof T): void {
      object[key] = ( object?.$$deepKeys && object.$$deepKeys.includes(key) ) ? cloneDeep(object.$$saved[key]) : object.$$saved[key];
   }

   public rollbackObject<T extends StatefulObject<T>>(object: T): void {
      this.removeRootLevelAdditions(object);
      object = assign(object, cloneDeep(object.$$saved));
   }

   public saveObject<T extends StatefulObject<T>>(object: T, setter: (obj: T) => Observable<any>, withRollback=false): Observable<any> {
      object.$$saving = true;
      var original = clone(object);
      object.$$state = object.$$state === 'new' ? 'creating' : 'saving';
      return setter(object)
         .pipe(
            map((result) => {
               this.saveSaved(object);
               object.$$state = 'clean';
               return result;
            }),
            catchError((error) => {
               object.$$state = original.$$state;
               if (withRollback) {
                  angular.extend(object, cloneDeep(pickBy(object.$$saved, (value, key) => {
                     if (key.indexOf('$$') === 0) return false;
                     return true;
                  })));
               }
               return throwError(error);
            }),
            finalize(() => {
               if (isEmpty(object.$$savingStates)) {
                  object.$$saving = false;
               }
            }),
         );
   };

   public saveCollectionKey<T extends StatefulObject<T>>(collection: T[], key: keyof T, setter: (obj: T[], key: keyof T) => Observable<any>): Observable<any> {
      var originalCollection = clone(collection);
      originalCollection.forEach((object) => {
         object.$$saving = true;
         object.$$savingStates[key] = 'saving';
      });

      return setter(collection, key)
         .pipe(
            map((result) => {
               originalCollection.forEach((object) => { object.$$saved[key] = object[key]; });
               return result;
            }),
            catchError(error => {
               originalCollection.forEach((object) => { this.rollbackProperty(object, key); });
               return throwError(error);
            }),
            finalize(() => {
               originalCollection.forEach((object) => {
                  delete object.$$savingStates[key];
                  if (isEmpty(object.$$savingStates)) {
                     object.$$saving = false;
                  }
               });
            }),
         );
   };

   public saveCollection<T>(collection: T[], setter: (obj: T[]) => Observable<any>): Observable<any> {
      var originalCollection = clone(collection);
      var originalStates = lMap(originalCollection, '$$state');

      originalCollection.forEach((object) => {
         object.$$saving = true;
         object.$$state = object.$$state === 'new' ? 'creating' : 'saving';
      });

      return setter(collection)
         .pipe(
            map((result) => {
               originalCollection.forEach((object) => {
                  this.saveSaved(object);
                  object.$$state = 'clean';
               });
               return result;
            }),
            catchError((error) => {
               originalStates.forEach((state, i) => {
                  originalCollection[i].$$state = state;
               });
               return throwError(error);
            }),
            finalize(() => {
               originalCollection.forEach((object) => {
                  if (isEmpty(object.$$savingStates)) {
                     object.$$saving = false;
                  }
               });
            }),
         );
   };

   /*
   This will initialise a new interval timer _every_ call, so you should
   only register a collection once.
   We will need to tear down the dirty watchers (i.e. clearInterval) on
   component destroy, when the pages consuming this are frontend routed.
   Returns the timer's id which consumers can use for this.
   */
   public registerDirtyWatchers<T extends WatchedObject>(objectGetter: () => T[], keysToIgnore?: string[], scope?: any): number {
      return window.setInterval(() => {
         const objects = objectGetter();
         if (objects) {
            if (Array.isArray(objects)) {
               objects.forEach(object => {
                  const dirty = object.$$saved !== undefined &&
                     !deepEqual(
                        omit(filterKeysStartingWith(object, '$$'), keysToIgnore),
                        omit(filterKeysStartingWith(object.$$saved,'$$'), keysToIgnore),
                     );
                  object.$$dirty = dirty;
                  if (object.$$dirty) {
                     this.navigationService.addToWarningList(object);
                  } else {
                     this.navigationService.removeFromWarningList(object);
                  }
               });
            } else {
               return  throwError('objectGetter must return an array');
            }
         }
      }, 100);
   };

   private saveSaved(object: any): void {
      if (object.$$managedBySkeletonCache) return;
      object.$$saved = cloneDeep(omitBy(object, (value, key) => key.startsWith('$$')));
   }

   private removeRootLevelAdditions(object: any) {
      // Remove any properties added to root of object since $$saved
      // _.assign removes nested additions (as does a shallow replace)
      Object.keys(object).slice().forEach(key => {
         if (key.startsWith('$$')) return;
         if (!object.$$saved.hasOwnProperty(key)) {
            delete object[key];
         }
      });
   }
}
