import { Injectable, Inject, OnDestroy } from '@angular/core';
import { map, take, takeUntil, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { NavigationService } from '../utils/navigation/navigation.service';
import { SaveTransactionService } from '../utils/save-transaction/save-transaction.service';
import { Endpoint, EndpointType } from '../types';
import { EndpointTypes } from '../types';
import { Service } from '../../edge-services/service.interface';
import { EdgeServer } from '../../edge-servers/types';
import { ArrivalsFolder } from '../api/arrivals-folder.interface';
import { EdgeServersApiService } from './edge-servers-api.service';
import { EndpointsApiService } from './endpoints.api.service';
import { ArrivalsFolderEndpointAssignmentsApiService } from './arrivals-folder-endpoint-assignments-api.service';
import { Assignment } from '../types/assignment.interface';
import { CardFormSetting } from '../types';
import { ArrivalsFoldersApiService } from './arrivals-folders-api.service';
import { AccountsApiService } from '../api/accounts/accounts.api.service';
import { AlertService } from './alert.service';
import { PageService } from './page.service';


@Injectable({
   providedIn: 'root',
})
export class EndpointCardsService implements OnDestroy {

   public readonly endpoints$: Observable<Endpoint[]>;
   public readonly selectedEndpoint$: Observable<Endpoint>;
   public readonly edgeServer$: Observable<EdgeServer>;
   public readonly scroll$: Observable<void>;

   public edgeServer: EdgeServer;
   public selectedEndpoint: Endpoint | null;
   public selectedEndpointType: EndpointType;
   public originalAccount = null;
   public originalArrivalsFolderId = null;

   private _endpointTypes: EndpointTypes;
   private _endpoints: Endpoint[] = [];
   private _assignments: Assignment[] = [];
   private _service: Service;

   private _endpointsSubject = new BehaviorSubject<Endpoint[]>([]);
   private _endpointSelectSubject = new Subject<Endpoint>();
   private _edgeServerSubject = new Subject<EdgeServer>();
   private _scrollIntoViewSubject = new Subject<void>();

   private destroy$ = new Subject<void>();
   private page: Page;

   constructor(
      private alertService: AlertService,
      private pageService: PageService,
      private saveTransactionService: SaveTransactionService,
      @Inject('NavigationService') private navigationService: NavigationService,
      private accountsApiService: AccountsApiService,
      private arrivalsFoldersApiService: ArrivalsFoldersApiService,
      private edgeServersApiService: EdgeServersApiService,
      private endpointsApiService: EndpointsApiService,
      private arrivalsFolderEndpointAssignmentsApiService: ArrivalsFolderEndpointAssignmentsApiService,
   )
   {
      this.endpoints$ = this._endpointsSubject.asObservable();
      this.selectedEndpoint$ = this._endpointSelectSubject.asObservable();
      this.edgeServer$ = this._edgeServerSubject.asObservable();
      this.scroll$ = this._scrollIntoViewSubject.asObservable();
      this.page = this.pageService.page;
   }


   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public initEndpoints(service: Service, endpointTypes: EndpointTypes): Observable<Endpoint[]> {
      this._endpointTypes = endpointTypes;
      this._service = service;
      const edgeServer$ = this.edgeServersApiService.fetchEdge(this.page.edgeServerId, false);
      const endpoints$ = this.endpointsApiService.fetchForService(service, false);
      const assignments$ = this.arrivalsFolderEndpointAssignmentsApiService.fetchForService(service, false);
      return combineLatest([edgeServer$, endpoints$, assignments$])
         .pipe(
            switchMap(([edgeserver, endpoints, assignments]) =>  this.endpointsEffects(edgeserver, endpoints, assignments)),
         );
   }

   public selectEndpoint(endpoint: Endpoint) {
      this.selectedEndpoint = endpoint;
      if (endpoint) {
         this.originalAccount = this.selectedEndpoint.account;
         this.originalArrivalsFolderId = this.selectedEndpoint.arrivalsFolderId;
         this.selectedEndpointType = this._endpointTypes[this.selectedEndpoint.typeId];
         this.loadAccount(this.selectedEndpoint);
      } else {
         this.selectedEndpointType = null;
      }

      this.scrollIntoViewSelectedEndpoint();
      this._endpointSelectSubject.next(this.selectedEndpoint);
   }

   public endpointIsSelected(endpoint: Endpoint): boolean {
      return this.selectedEndpoint && (
         endpoint.id
            ? endpoint.id === this.selectedEndpoint.id
            : endpoint.$$temporaryId === this.selectedEndpoint.$$temporaryId
      );
   }

   public endpointDestinationChanged(): boolean {
      return this.selectedEndpoint.id && (this.originalAccount !== this.selectedEndpoint.account || this.originalArrivalsFolderId !== this.selectedEndpoint.arrivalsFolderId);
   }

   public restoreEndpointChanges(): void {
      this.selectedEndpoint.arrivalsFolderId = this.originalArrivalsFolderId;
      this.selectedEndpoint.account = this.originalAccount;
      this.loadAccount(this.selectedEndpoint);
   }

   public loadAccount(endpoint: Endpoint): void {
      const unassignedChoice = { id: null, name: "** Unassigned **" };
      const createNewChoice = { id: "NEW", name: "** Create New Folder **" };
      const noWorkflowChoice = { id: "noWorkflow", name: "No workflow (use arrivals folder ingest format)" };

      this.accountsApiService.populateSkeleton(endpoint.account)
      .pipe(takeUntil(this.destroy$)).subscribe(account => {
         if (!account.id) return; // unset account has no arrivals
         if (account.id === endpoint.account.id) {
            // Somehow skeleton cache produces a different object sometimes, unify things
            endpoint.account = account;
            endpoint.account.workflowChoices = [noWorkflowChoice];
         }
         this.accountsApiService.fetchWorkflows(account.id).pipe(takeUntil(this.destroy$)).subscribe(workflows => {
            account.workflows = workflows;
            const sortedWorkflows = workflows.slice().sort((a: any, b: any) => a.name.localeCompare(b.name));
            account.workflowChoices = [noWorkflowChoice, ...sortedWorkflows];
         });
         this.arrivalsFoldersApiService.fetchForAccount(account.id)
            .pipe(
               take(1),
               catchError(error => {
                  this.alertService.show({
                     text: 'Error fetching arrivals folderss: ' + error.message,
                     neverRemove: true,
                     type: 'danger'
                  });
                  if (error.status === 403) {
                     account.arrivalsFolderChoices = [{ id: null, name: "** An account manager must set **" }];
                  }
                  throw error;
               })
            )
            .subscribe(arrivalsFolders => {
               account.arrivalsFolders = arrivalsFolders;
               account.arrivalsFolderChoices = [].concat(unassignedChoice, arrivalsFolders, createNewChoice);
            });
      });
   }

   public addEndpoint(chosenEndpointType: EndpointType): void {
      const newEndpoint = this.endpointsApiService.getNewEndpoint(this._service, chosenEndpointType);
      this.selectEndpoint(newEndpoint);
      this._endpoints.push(newEndpoint);
      this._endpointsSubject.next([...this._endpoints]);
   }

   public removeEndpoint(endpoint: Endpoint): void {
      this.selectEndpoint(null);
      this.navigationService.removeFromWarningList(endpoint);
      const index = this._endpoints.findIndex(item => item.id === endpoint.id);
      if (index > -1) {
         this._endpoints.splice(index, 1);
         this._endpointsSubject.next([...this._endpoints]);
      }
   }

   public selectedEndpointVersionAtLeast(atLeast: number): boolean {
      return this.edgeServer.endpointVersions &&
         this.edgeServer.endpointVersions[this.selectedEndpointType.id] >= atLeast;
   }

   public scrollIntoViewSelectedEndpoint(): void {
      this._scrollIntoViewSubject.next();
   }

   public accountChanged(): void {
      this.selectedEndpoint.arrivalsFolderId = null;
      if (!this.selectedEndpoint.account) {
         // Clearing account choice resets this to undefined, put back dummy object
         this.selectedEndpoint.account = this.accountsApiService.getSkeleton();
      }
      this.loadAccount(this.selectedEndpoint);
   };

   public arrivalsFolderForEndpoint(endpoint: Endpoint): ArrivalsFolder | null {
      const assignment = this._assignments.find(item => item.endpoint === endpoint);
      return assignment ? assignment.arrivalsFolder : null;
   }

   public endpointSettingOption(endpointType: EndpointType, optKey: string = 'autoCapture'): CardFormSetting | null {
      // Returns any endpoint setting that will show on the card
      // To avoid returning new array from angular function causing infdig error (infinite digest loop)
      // our html only supports one entry and also html only supports a select.
      if ( endpointType.form ) {
         const form = endpointType.form.filter(item => item.name === optKey );
         return form.length > 0 ? form[0] : null;
      }
      else {
         return null;
      }
   }

   public cardFormChange(endpoint: Endpoint): void {
      this.endpointOptionSync(endpoint, 'autoCapture');
      this.endpointsApiService.saveEndpoint(endpoint)
         .pipe(
            take(1),
         )
         .subscribe(() => {
         this.alertService.show({
            text: 'Saved ' + endpoint.name,
            type: 'success'
         });
      }, (error: string) => {
         this.alertService.show({
            text: 'Unable to save ' + endpoint.name + ' - ' + error,
            type: 'danger'
         });
      });
   }

   public endpointOptionSync(endpoint: Endpoint, optKey: string): void {
      const setOptions = this.endpointSettingOption(this._endpointTypes[endpoint.typeId], optKey).valueLabels.find((item) => item.value === endpoint.options[optKey].value).setOptions;
      if (setOptions) {
         for (const optionKey in setOptions) {
            const optionValue = setOptions[optionKey];
            for (const fieldKey in optionValue) {
               endpoint.options[optionKey][fieldKey] = optionValue[fieldKey];
            }
         }
         this.selectEndpoint(endpoint);
      }
   }

   private endpointsEffects(edgeServer: EdgeServer, endpoints: Endpoint[], assignments: Assignment[]): Observable<Endpoint[]> {
      endpoints = endpoints.filter(endpoint => !(this._endpointTypes[endpoint.typeId] && this._endpointTypes[endpoint.typeId].category === 'infrastructure'));
      endpoints.forEach(endpoint => this.loadAccount(endpoint));
      this._assignments = assignments;
      this._edgeServerSubject.next(edgeServer);
      this.edgeServer = edgeServer;
      this._endpoints = ( endpoints ) ? endpoints : [];
      this._endpointsSubject.next(this._endpoints);
      this.saveTransactionService.registerDirtyWatchers(() => this._endpoints, ['pendingSettings']);
      return of(this._endpoints);
   }
}
