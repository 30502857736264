<section *ngIf="(state$ | async) as state">
   <div class="table-container">
      <div *ngIf="state.loading || fetching" class="overlay-loading">
         <fbdn-icon icon="loading"></fbdn-icon>
      </div>

      <div *ngIf="state.error" class="error">
         There has been an error loading the {{entityPluralName}}: {{state.error}}
      </div>

      <mat-table
         *ngIf="!state.loading &&  !fetching && state.value as dataSource"
         [dataSource]="dataSource"
         matSort
         [matSortActive]="sortSelected.active"
         [matSortDirection]="sortSelected.direction"
         (matSortChange)="onSortChange($event)"
      >
         <ng-container *ngIf="selectableColumnKey" matColumnDef="Select">
            <mat-header-cell
               *matHeaderCellDef
               [ngClass]="{'column-separator': resizeEnabled}"
               class="select-all narrow-column"
               >
               <mat-checkbox
                  color="primary"
                  [disabled]="!dataSource.data.length"
                  [checked]="allRowsSelected"
                  (change)="selectAll(dataSource.data, $event.checked)"
               ></mat-checkbox>
            </mat-header-cell>
            <mat-cell
               *matCellDef="let element"
               ngClass="narrow-column"
            >
               <mat-checkbox
                  color="primary"
                  [(ngModel)]="selectedData[element[selectableColumnName].selectValue]"
                  (ngModelChange)="updateAllSelected(dataSource.data)"
               ></mat-checkbox>
            </mat-cell>
         </ng-container>
         <ng-container *ngIf="optionalColumnNames.length" matColumnDef="dropDownColsMenu">
            <mat-header-cell
               *matHeaderCellDef
               [ngClass]="{'column-separator': resizeEnabled}"
               class="narrow-column opt-cols"
               >
                  <button mat-icon-button (click)="openCloseOptMenu()" class="dropdown-button">
                     <span class="material-icon"> expand_more </span>
                  </button>
                  <div *ngIf="dropdownMenuIsOpen" class="options-drop-menu">
                     <div class="options-menu-item" *ngFor="let colName of optionalColumnNames">
                        <div class="checkbox-row">
                           <span class="checkbox-label">{{colName}}</span>
                           <mat-checkbox
                              [checked]="schema.get(colName).optionalEnabled"
                              (change)="updateVisibleColumns(colName, $event.checked)"
                              (click)="$event.stopPropagation()"
                              >
                           </mat-checkbox>
                        </div>
                     </div>
                  </div>
            </mat-header-cell>
            <mat-cell
               *matCellDef="let element"
               ngClass="narrow-column"
            >
            </mat-cell>
         </ng-container>

         <ng-container *ngFor="let colName of columnNames; trackBy: trackByFn">
            <ng-container *ngIf="isTableColumn(colName)" [matColumnDef]="colName">
               <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.width]="schema.get(colName)?.fixedWidth"
                  [style.min-width]="schema.get(colName)?.minWidth"
                  [bbResizeColumn]="schema.get(colName).resizeable"
                  [index]="getColumnPosition(colName)"
                  [disabled]="!schema.get(colName).sortable || !dataSource.data.length"
                  [ngClass]="{'component-column': this.schema.get(colName).dynamicComponent,
                              'narrow-column': this.schema.get(colName).narrow,
                              'column-separator': resizeEnabled,
                              'fixed-width': schema.get(colName)?.fixedWidth}"
                  >
                  {{colName}}
               </mat-header-cell>

               <mat-cell
                  *matCellDef="let element"
                  [matTooltip]="element[schema.get(colName).key]?.tooltip || ''"
                  matTooltipPosition="below"
                  matTooltipClass="near-to-trigger-tooltip"
                  [ngClass]="{'component-column': this.schema.get(colName).dynamicComponent,
                              'narrow-column': this.schema.get(colName).narrow }"
               >
                  <div [ngClass]="(schema.get(colName).resizeable)?'resizable-cell':'flex-cell'">
                     <ng-container [ngSwitch]="true">
                        <a
                           *ngSwitchCase="!!element[schema.get(colName).key].href"
                           [href]="element[schema.get(colName).key].href"
                        >
                           {{element[schema.get(colName).key].text}}
                        </a>

                        <ndc-dynamic
                           *ngSwitchCase="!!element[schema.get(colName).key].component"
                           [ndcDynamicComponent]="element[schema.get(colName).key].component"
                           [ndcDynamicInputs]="element[schema.get(colName).key].inputs"
                           [ndcDynamicOutputs]="element[schema.get(colName).key].outputs"
                        ></ndc-dynamic>

                        <ng-container *ngSwitchCase="element[schema.get(colName).key].hasOwnProperty('active')">
                           <span *ngIf="element[schema.get(colName).key].active; else inactive" class="material-icon active">
                              check
                           </span>
                           <ng-template #inactive>
                              <span class="material-icon inactive">
                                 close
                              </span>
                           </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                           {{element[schema.get(colName).key].text}}
                        </ng-container>
                     </ng-container>
                  </div>
               </mat-cell>
            </ng-container>
         </ng-container>

         <mat-header-row [ngClass]="{'conditional-row-style': resizeEnabled}" *matHeaderRowDef="columnNames"></mat-header-row>
         <mat-row [ngClass]="{'conditional-row-style': resizeEnabled}" *matRowDef="let row; columns: columnNames;"></mat-row>

      </mat-table>
      <section *ngIf="state.value.data?.length">
         <bb-api-paginator
            [loading]="state.loading"
            [totalRowCount]="totalRowCount"
            [pageSize]="pageSize"
            (paginationChanged)="onPaginationChange($event)"
            [resetOnTotalRowChange]="resetOnTotalRowChange"
            [hideAllItems]="hideAllItems"
            [hideFirstLast]="hideFirstLast"
            [resetStart]="resetStart"
            [hideTotalItems]="hideTotalItems"
            [nextPageDisabled]="nextPageDisabled"
         ></bb-api-paginator>
      </section>
   </div>

</section>
