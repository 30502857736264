
import { Injectable, Inject } from '@angular/core';
import { Observable, of, from, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { SaveTransactionService } from '../utils/save-transaction/save-transaction.service';
import { Service } from '../../edge-services/service.interface';
import { Endpoint, MarshalledEndpoint, EndpointType, EndpointTypes } from '../types/endpoint.interface';
import { WatchfolderEndpoints, StreamEndpoints } from '../types';
import { AsyncCacheService } from '../utils/async-cache.service';
import { DynamicSchema } from '../dynamic-form/ng/dynamic-schema';
import { ActionResponse } from '../../shared/types/notification.interface';
import { EdgeServer, EdgeServerTemplate } from '../../edge-servers/types';
import { ApiUpdatesService } from "../utils/updates/api-updates.service";
import { Skeleton } from '../types/skeleton.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Collection, RawCollection } from '../types';
import { ApiConfigService } from './api-config.service';
import { RAW_DATA } from '../constants/raw-data';
import { AccountsApiService } from '../api/accounts/accounts.api.service';
import { EdgeServicesApiService } from './edge-services.api.service';
import { SkeletonCacheService } from '../utils/skeleton-cache.service';

@Injectable({
   providedIn: 'root',
})
export class EndpointsApiService {
   private lastTemporaryId = 0;
   private apiUpdates: any;
   private asyncCache: any;
   private skeletonCache: any;
   private apiVersion: string;
   private httpHeaders: HttpHeaders;
   private rawDataOptions = { headers: RAW_DATA, };

   constructor(
      private skeletonCacheService: SkeletonCacheService,
      private edgeServicesApi: EdgeServicesApiService,
      private saveTransactionService: SaveTransactionService,
      private accountsApiService: AccountsApiService,
      private httpClient: HttpClient,
      private apiUpdatesService: ApiUpdatesService,
      private asyncCacheService: AsyncCacheService<Endpoint>,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache('EndpointsApi');
      this.skeletonCache = this.skeletonCacheService.initSkeletonCache('EndpointsApi', {
         marshall: this.marshall,
         unmarshall: x => this.unmarshall(x),
      });
      this.apiUpdates = this.apiUpdatesService.initApiUpdatesService('EndpointsApi', (endpoint: Endpoint) => { this.populateSkeleton(endpoint, true).pipe(take(1)).subscribe(); });
      this.apiVersion = ApiConfigService.settings.version;
      this.httpHeaders = new HttpHeaders().set('X-Api-Version', this.apiVersion);

   }

   public fetchEndpointType(endpointTypeId: string): Observable<EndpointType> {
      const api = this.httpClient.get<EndpointType>('/api/endpointTypes/' + endpointTypeId);
      return this.asyncCache.fetch('edge-server-endpoint-type-' + endpointTypeId, api);
   }

   public fetchEndpointTypesForService(serviceId: string): Observable<EndpointTypes> {
      const api = this.httpClient.get<EndpointTypes>('/api/services/' + serviceId + '/endpointTypes'
);
      return this.asyncCache.fetch('edge-server-endpoint-types-' + serviceId, api)
         .pipe(
            map((endpointTypes) => this.endpointObjectType(endpointTypes as EndpointType[])),
         );
   }

   public fetch(endpoint: Endpoint | string, forceFetch: boolean=false): Observable<Endpoint> {
      if (!endpoint) throw new TypeError(`endpointId is ${endpoint}`);
      const endpointId: string  = (typeof endpoint === 'string') ? endpoint : (endpoint as Endpoint).id;
      const api = this.httpClient.get<Endpoint>('/api/endpoints/' + endpointId,)
         .pipe(
            map((endp: Endpoint) => this.skeletonCache.unmarshall(endp))
         );
      return this.asyncCache.fetch('endpoint-' + endpointId, api, forceFetch)
         .pipe(
            map((endp: Endpoint) => this.apiUpdates.subscribeToUpdate(endp)),
         );
   }

   public fetchForService(service: Service, forceFetch: boolean=false): Observable<Endpoint[]> {

      const api = (!service.id) ? of({results: []}): this.httpClient.get<RawCollection>('/api/services/' + service.id + '/endpoints', this.rawDataOptions)
         .pipe(
            map((collection: Collection) => this.skeletonCache.unmarshallCollection(collection)),
         );
      return this.asyncCache.fetch('service-' + (service.$$temporaryId || service.id), api, forceFetch)
         .pipe(
            map((collection) => this.apiUpdates.subscribeToCollection(collection, this.fetchForService.bind(this), [service, true], true)),
         );
   }

   public fetchForServer(server: EdgeServer & EdgeServerTemplate, forceFetch: boolean=false): Observable<Endpoint[]> {
      const api = this.httpClient.get<RawCollection>('/api/edgeServers/' + server.id + '/endpoints', this.rawDataOptions)
         .pipe(
            map((collection: Collection) => this.skeletonCache.unmarshallCollection(collection)),
         );
      return this.asyncCache.fetch('server-' + (server.$$temporaryId || server.id), api, forceFetch)
         .pipe(
            map((collection) => this.apiUpdates.subscribeToCollection(collection, this.fetchForServer.bind(this), [server, true], true)),
         );
   }

   public fetchForServerId(serverId: string, forceFetch: boolean=false): Observable<Endpoint[]> {
      const api = this.httpClient.get<Endpoint[]>('/api/edgeServers/' + serverId + '/endpoints')
         .pipe(
            map(endpoints => endpoints.map(ep =>  { ep.category = (ep.typeId in WatchfolderEndpoints)? 'source': (ep.typeId in StreamEndpoints) ? 'stream': null; return ep; }),),
         );
      return this.asyncCache.fetch('serverId-endpoints' +  serverId, api, forceFetch);
   }

   public fetchForArrivalsId(arrivalsId: string, forceFetch: boolean=false): Observable<Endpoint[]> {
      const api = this.httpClient.get<Endpoint[]>('/api/arrivalsFolders/' + arrivalsId + '/endpoints')
         .pipe(
            map(endpoints => endpoints.map(ep =>  {
                  ep.category = (ep.typeId in WatchfolderEndpoints)? 'source': (ep.typeId in StreamEndpoints) ? 'stream': null;
                  ep.edgeServerId = ep.service.edgeServerId;
                  return ep;
               }),
            ),
         );
      return this.asyncCache.fetch('arrivalsId-endpoints' +  arrivalsId, api, forceFetch);
   }

   public fetchForAccountId(accountId: string, siteLevel: boolean=false, forceFetch: boolean=false): Observable<Endpoint[]> {
      const api = this.httpClient.get<Endpoint[]>('/api/accounts/' + accountId + '/endpoints' + ( siteLevel ? '?withSite=1': ''))
         .pipe(
            map(endpoints => endpoints.map(ep =>  { ep.category = (ep.typeId in WatchfolderEndpoints)? 'source': (ep.typeId in StreamEndpoints) ? 'stream': null; return ep; }),),
         );
      return this.asyncCache.fetch('accountId-endpoints' + accountId, api, forceFetch);
   }

   public fetchForAccount(accountId: string, forceFetch: boolean=false): Observable<Endpoint[]> {
      const api = this.httpClient.get<RawCollection>('/api/accounts/' + accountId + '/endpoints', this.rawDataOptions)
         .pipe(
            map((collection: Collection) => this.skeletonCache.unmarshallCollection(collection)),
         );
      return this.asyncCache.fetch('account-endpoints' + accountId, api, forceFetch)
         .pipe(
            map((collection) => this.apiUpdates.subscribeToCollection(collection, this.fetchForAccount.bind(this), [accountId, true], true)),
         );
   }

   public getSkeleton(endpointId: string): any {
      return this.skeletonCache.get(endpointId);
   }

   public getNewEndpoint(service, endpointType) {
      const endpoint = this.skeletonCache.unmarshall({
         $$state: 'new',
         $$temporaryId: 'new-endpoint-' + ++this.lastTemporaryId,
         id: null,
         typeId: endpointType.id,
         name: 'New ' + endpointType.name,
         service: service,
         accountId: null,
         siteId: null,
         options: DynamicSchema.defaultValuesForSchema(endpointType.schema),
      });
      return endpoint;
   }

   public createEndpoint(endpoint: Endpoint): Observable<Endpoint> {
      endpoint.pendingSettings = endpoint.online;
      return this.saveTransactionService.saveObject(endpoint, () => {
         return this.httpClient.post<Endpoint>('/api/services/' + endpoint.service.id + '/endpoints', this.skeletonCache.marshall(endpoint), {headers: this.httpHeaders})
            .pipe(
               map((ep: Endpoint) => {
                  endpoint.id = ep.id;
                  this.skeletonCache.add(endpoint);
                  return ep;
               }),
               map((endp: Endpoint) => this.skeletonCache.unmarshall(endp)),
               map((endp: Endpoint) => this.apiUpdates.subscribeToUpdate(endp)),
            );
      });
   }

   public saveEndpoint(endpoint: Endpoint): Observable<Endpoint> {
      if (!endpoint.id) throw new TypeError(`endpoint.id is ${endpoint.id}`);
      endpoint.pendingSettings = endpoint.online;
      return this.saveTransactionService.saveObject(endpoint, () => {
         return this.httpClient.post<Endpoint>('/api/endpoints/' + endpoint.id, this.skeletonCache.marshall(endpoint))
            .pipe(
               map((endp) => this.skeletonCache.unmarshall(endp)),
            );
         });
   }

   public performAction(endpointId: string, action: string, data: any = {}): Observable<ActionResponse> {
      return this.httpClient.post<ActionResponse>('/api/endpoints/' + endpointId + '/actions/' + action, data || {});
   }

   public saveName(endpoint: Endpoint) {
      if (!endpoint.id) throw new TypeError(`endpoint.id is ${endpoint.id}`);
      endpoint.pendingSettings = endpoint.online;
      return this.saveTransactionService.save(endpoint, 'name', (e: Endpoint, key: string) => {
         return this.httpClient.post('/api/endpoints/' + e.id, { name: e[key] })
            .pipe(
               map((endp: Endpoint) => this.skeletonCache.unmarshall(endp)),
            );
         });
   };

   public deleteEndpoint(endpoint: Endpoint): Observable<any> {
      endpoint.$$deleting = true;
      const api = this.httpClient.post<Endpoint>('/api/endpoints/' + endpoint.id + '/trash', {});
      return (!endpoint.id) ? of() : api
         .pipe(
            catchError(error => {
               endpoint.$$deleting = false;
               return throwError(error);
            }),
         );
   }

   public populateSkeleton(skeleton: Skeleton, forceFetch=false): Observable<Endpoint> {
      return this.fetch(skeleton.id, forceFetch);
   }

   public existOnCloud(endpoint: Endpoint): boolean {
      return endpoint.id !== null;
   }

   public endpointBad(endpoint: Endpoint): boolean {
      return endpoint.bad;
   }

   public notAvailableEndpoint(endpoint: Endpoint): boolean {
      return !endpoint.online || endpoint.status === 'disabled';
   }

   public endpointStatus(endpoint: Endpoint): string {
      return endpoint.status;
   }

   private endpointObjectType(endpointTypes: EndpointType[]): EndpointTypes {
      const output: EndpointTypes = {};
      endpointTypes.forEach(element => output[element.id] = element);
      return output;
   }

   private marshall(endpoint: Endpoint): MarshalledEndpoint {
      return {
         id: endpoint.id,
         name: endpoint.name,
         typeId: endpoint.typeId,
         accountId: endpoint.account.id || null,
         siteId: endpoint?.siteId,
         serviceId: endpoint.service.id,
         arrivalsFolderId: endpoint.arrivalsFolderId,
         workflowId: endpoint.workflowId,
         options: endpoint.options
      };
   }

   private unmarshall(endpoint: Endpoint):  Endpoint {
      if (!endpoint.account) {
         endpoint.account = this.accountsApiService.getSkeleton(endpoint.accountId);
      }
      if (!endpoint.service) {
         this.edgeServicesApi.fetchService(endpoint.serviceId)
         .pipe(
            take(1),
         )
         .subscribe(srv => {
            endpoint.service = srv;
            this.saveTransactionService.prepare(endpoint, ['options']);
         });
      }
      return this.saveTransactionService.prepare(endpoint, ['options']);
   }
}
